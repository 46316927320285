/* fonts */
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';


@tailwind base;
@tailwind components;
@tailwind utilities;


*::-webkit-scrollbar {
  width: 3;
}
body {
  scroll-behavior: smooth;
  font-family: Roboto, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", 
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  background: #ffffff;
  scroll-behavior: smooth;
  margin: 0 15px;
}
input,
select {
  outline: none;
}
.batch_heart {
  background: tomato;
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  position: absolute;
  right: -24%;
  top: -9%;
  cursor: pointer;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* styles.css or App.css or index.css */

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -40px;
  }
  100% {
    stroke-dasharray: 100, 200;
    stroke-dashoffset: -140px;
  }
}

@keyframes full {
  0% {
    stroke-dasharray: 0 260;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 260 260;
    stroke-dashoffset: 0;
  }
}

.animate-ring {
  animation: dash 1.5s ease-in-out forwards;
}

.animate-progress {
  animation: full 1.5s ease-in-out forwards;
}

/* Simulate progress increment */
